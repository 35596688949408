import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
// import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import LangDropdown from "./LangDropdown";
import RegionDropdown from "./RegionDropdown";
import CustomMobileHeader from "magicTicketComponents/CustomMobileHeader/CustomMobileHeader";
import AvatarDropdown from "./AvatarDropdown";
import CartIcon from "./CartIcon";
import { useSelector } from "react-redux";
import i18n from "i18next";
import config from "config/config";
// import HaloweenBanner from "components/HaloweenBanner/HaloweenBanner";
// import FireworksOverlay from "components/NationalDayBanner/NationalDayBanner";
export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const user = useSelector((state: any) => state.user);
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      {/* <HaloweenBanner /> */}
      {/* <FireworksOverlay /> */}
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>
        <div className="md:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <CustomMobileHeader />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SearchDropdown />
            <SwitchDarkMode />
            {["saudi"].includes(config.region) && <LangDropdown />}
            <RegionDropdown />
            <CartIcon />
            <div className="px-1" />
            {user?.isLoggedIn === true ? (
              <AvatarDropdown />
            ) : (
              <ButtonPrimary href="/login">
                {i18n.t("HeaderSignIn")}
              </ButtonPrimary>
            )}
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <SearchDropdown />
            <CartIcon />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
